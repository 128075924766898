<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">大板镇{{ hamletName }}牧业统计</div> 
    </div>

    <div class="content_box">
      <!-- 1 -->
      <div class="content_item_box">
        <div class="left_box">
          <div class="title"><span>牲畜存栏</span></div>
          <bing-tu :value="data1" />
        </div>

        <div class="left_box">
          <div class="title"><span>防疫统计</span></div>
          <kong-xin-bing-tu :value="data2" />
        </div>
      </div> 

      <!-- 2 -->
      <div class="content_item_box">
        <div class="map_box">
          <hao-rao-qin v-if="hamletName == '浩饶沁嘎查'" />
          <tai-bu-dai v-if="hamletName == '太布呆嘎查'" />
          <da-leng v-if="hamletName == '大冷村'" />
          <gu-li-gu-tai v-if="hamletName == '古力古台嘎查'"/>
          <hong-qi v-if="hamletName == '乌兰图嘎嘎查'" />
          <ji-bu-tu v-if="hamletName == '吉布吐嘎查'" />
          <xi-ha v-if="hamletName == '西哈嘎查'" />
          <wen-gen-tu v-if="hamletName == '温根吐嘎查'" />
          <shi-jia-zi v-if="hamletName == '阿日班格日嘎查'"/>
          <su-ai-li v-if="hamletName == '苏艾力嘎查'" />
          <zhao-hu-du-ge v-if="hamletName == '昭胡都格嘎查'" />
          <weng-gen-mao-du v-if="hamletName == '翁根毛都嘎查'" />
          <tao-bai-cun v-if="hamletName == '套白村'" />
          <gui-le-su-tai v-if="hamletName == '归勒斯台嘎查'" />
        </div>

        <div class="context_box">
          全嘎查总土地面积 104 平方公里，
          其中：草木场面积 2.5万亩，耕地面积4.5万亩，林地面积3,4万亩，
          棚圈： 1400平米，饲草料加工设备：189台，青储窑：2100平米。
        </div>

        <div class="bottom_box">
          <div class="title"><span>死亡统计</span></div>
          <zhu-zhuang-tu class="chart" :value="data3" />
        </div>
      </div> 

      <!-- 3 -->
      <div class="content_item_box">
        <div class="pai_hang">
          <div class="title"><span>牧户统计</span></div>
          <div class="list_box">
            <div class="list_item">
              <div class="w_33_3_p text_center main_color">牧户</div> 
              <div class="w_33_3_p text_center main_color">养殖比</div> 
              <div class="w_33_3_p text_center main_color">电话</div> 
            </div>

            <div class="list_item" v-for="(item, index) in muHuList" :key="index" v-show="item.hamlet == $route.params.val">
              <!-- <span class="font_number" v-if="index < 9">0{{ index+1 }}</span> 
              <span class="font_number" v-else>{{ index+1 }}</span>  -->

              <div class="name ellipsis" @click="goToMuHuDetail(item.id)">{{ item.household_name }}</div> 

              <!-- 百分比 -->
              <div class="bgc ml_30">
                <div class="c" :style="{ width: item.cattle_num || 96 + '%' }"></div>
              </div>

              <div class="w_50_p text_left">
                <div class="font_number ls_2 main_color"> {{ item.phone_number }} </div>
              </div>

              <!-- <div class="value font_number">{{ item.cattle_num }}</div>  -->
            </div>

            <div class="list_item" v-for="(item, index) in testMuHuList" :key="index">
              <div class="name ellipsis" >{{ item.name }}</div> 

              <!-- 百分比 -->
              <div class="bgc ml_30">
                <div class="c" :style="{ width: item.cattle_num + '%' }"></div>
              </div>

              <div class="w_50_p text_left">
                <div class="font_number ls_2 main_color"> {{ item.phone_number }} </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import { getMuHuList } from '../../../../config/api';

  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue';
  import BingTu from '../charts/bing-tu.vue'
  import ZhuZhuangTu from '../charts/zhu-zhuang-tu.vue';
  import kongXinBingTu from '../charts/kong-xin-bing-tu.vue';
  import HaoRaoQin from '../maps/da-ban-hamlet/hao-rao-qin.vue';
  import TaiBuDai from '../maps/da-ban-hamlet/tai-bu-dai.vue';
  import DaLeng from '../maps/da-ban-hamlet/da-leng.vue';
  import GuLiGuTai from '../maps/da-ban-hamlet/gu-li-gu-tai.vue';
  import HongQi from '../maps/da-ban-hamlet/hong-qi.vue';
  import JiBuTu from '../maps/da-ban-hamlet/ji-bu-tu.vue';
  import WenGenTu from '../maps/da-ban-hamlet/wen-gen-tu.vue';
  import XiHa from '../maps/da-ban-hamlet/xi-ha.vue';
  import ShiJiaZi from '../maps/da-ban-hamlet/shi-jia-zi.vue';
  import SuAiLi from '../maps/da-ban-hamlet/su-ai-li.vue';
  import ZhaoHuDuGe from '../maps/da-ban-hamlet/zhao-hu-du-ge.vue';
  import WengGenMaoDu from '../maps/da-ban-hamlet/weng-gen-mao-du.vue';
  import TaoBaiCun from '../maps/da-ban-hamlet/tao-bai-cun.vue';
  import GuiLeSuTai from '../maps/da-ban-hamlet/gui-le-su-tai.vue';

  export default {
    name: 'showPage1',
    components: {
    BaLinYouQiMap,
    BingTu,
    ZhuZhuangTu,
    kongXinBingTu,
    HaoRaoQin,
    TaiBuDai,
    DaLeng,
    GuLiGuTai,
    HongQi,
    XiHa,
    WenGenTu,
    ShiJiaZi,
    SuAiLi,
    JiBuTu,
    ZhaoHuDuGe,  
    WengGenMaoDu,
    TaoBaiCun,
    GuiLeSuTai
  },
        
    data() {
      return {
        activeIndex: 0,

        data1: {},
        data2: {},
        data3: {},

        muHuList: [],
        testMuHuList: [
          { name: '孟和巴根', cattle_num: 89,  phone_number: 17643562345 },
          { name: '那顺乌力吉', cattle_num: 85, phone_number: 13843652345 },
          { name: '巴图吉日嘎啦', cattle_num: 80, phone_number: 15843573290 },
          { name: '额尔顿宝力高', cattle_num: 78, phone_number: 15743679232 },
          { name: '巴雅尔图', cattle_num: 76, phone_number: 13743663290 },
          { name: '孟和', cattle_num: 73, phone_number: 18943173360 },
          { name: '白嘎力', cattle_num: 70, phone_number: 18643777291 },
          { name: '威力斯', cattle_num: 67, phone_number: 15843543256 },
          { name: '宝乐尔', cattle_num: 65, phone_number: 13843763233 },
          { name: '特牧日巴根', cattle_num: 62, phone_number: 15943667290 },
          { name: '巴根那', cattle_num: 60, phone_number: 15743763234 },
          { name: '吉日嘎啦', cattle_num: 57, phone_number: 15643543243 },
          { name: '宝音图', cattle_num: 55, phone_number: 13743665329 },
          { name: '胡琴', cattle_num: 52, phone_number: 15643664329 },
          { name: '那木日', cattle_num: 50, phone_number: 13843653259 },
          { name: '牧仁', cattle_num: 43, phone_number: 15943345365 },
          { name: '银山', cattle_num: 35, phone_number: 13843853249 },
        ],
        hamletName: '',

        list: [
          { name: '牧户1', value: 231 },
          { name: '牧户2', value: 210 },
          { name: '牧户3', value: 200 },
          { name: '牧户4', value: 190 },
          { name: '牧户5', value: 180 },
          { name: '牧户6', value: 170 },
          { name: '牧户7', value: 160 },
          { name: '牧户8', value: 150 },
          { name: '牧户9', value: 140 },
          { name: '牧户10', value: 130 },
          { name: '牧户11', value: 120 },
          { name: '牧户12', value: 110 },
          { name: '牧户13', value: 100 },
          { name: '牧户14', value: 90 },
          { name: '牧户15', value: 85 },
          { name: '牧户16', value: 80 },
          { name: '牧户17', value: 75 },
          { name: '牧户18', value: 70 },
          { name: '牧户19', value: 65 },
          { name: '牧户20', value: 60 },
          { name: '牧户21', value: 55 },
          { name: '牧户22', value: 50 },
          { name: '牧户23', value: 30 },
          { name: '牧户24', value: 25 },
          { name: '牧户25', value: 20 },
          { name: '牧户26', value: 18 },
          { name: '牧户27', value: 15 },
          { name: '牧户28', value: 12 },
          { name: '牧户29', value: 10 },
        ],
        list1: [
          { name: '牧户1', value: 231 },
          { name: '牧户2', value: 210 },
          { name: '牧户3', value: 200 },
          { name: '牧户4', value: 190 },
          { name: '牧户5', value: 180 },
          { name: '牧户6', value: 170 },
          { name: '牧户7', value: 160 },
          { name: '牧户8', value: 150 },
          { name: '牧户9', value: 140 },
          { name: '牧户10', value: 130 },
          { name: '牧户11', value: 120 },
          { name: '牧户12', value: 110 },
          { name: '牧户13', value: 100 },
          { name: '牧户14', value: 90 },
          { name: '牧户15', value: 85 },
          { name: '牧户16', value: 80 },
          { name: '牧户17', value: 75 },
          { name: '牧户18', value: 70 },
          { name: '牧户19', value: 65 },
          { name: '牧户20', value: 60 },
          { name: '牧户21', value: 55 },
          { name: '牧户22', value: 50 },
          { name: '牧户23', value: 30 },
          { name: '牧户24', value: 25 },
          { name: '牧户25', value: 23 },
          { name: '牧户26', value: 20 },
          { name: '牧户27', value: 18 },
          { name: '牧户28', value: 16 },
          { name: '牧户29', value: 12 },
        ],

        list2: [
          { name: '牧户13', value: 131 },
          { name: '牧户14', value: 123 },
          { name: '牧户15', value: 113 },
          { name: '牧户16', value: 102 },
          { name: '牧户17', value: 98 },
          { name: '牧户18', value: 97 },
          { name: '牧户19', value: 88 },
          { name: '牧户1', value: 87 },
          { name: '牧户2', value: 85 },
          { name: '牧户3', value: 84 },
          { name: '牧户4', value: 81 },
          { name: '牧户5', value: 76 },
          { name: '牧户6', value: 74 },
          { name: '牧户7', value: 69 },
          { name: '牧户8', value: 68 },
          { name: '牧户9', value: 66 },
          { name: '牧户10', value: 65 },
          { name: '牧户11', value: 64 },
          { name: '牧户12', value: 62 },
          { name: '牧户20', value: 60 },
          { name: '牧户21', value: 55 },
          { name: '牧户22', value: 50 },
          { name: '牧户23', value: 30 },
          { name: '牧户24', value: 25 },
          { name: '牧户25', value: 24 },
          { name: '牧户26', value: 23 },
          { name: '牧户27', value: 22 },
          { name: '牧户28', value: 20 },
          { name: '牧户29', value: 19 },
        ],

        list3: [
          { name: '牧户20', value: 256 },
          { name: '牧户21', value: 245 },
          { name: '牧户22', value: 234 },
          { name: '牧户23', value: 213 },
          { name: '牧户24', value: 201 },
          { name: '牧户25', value: 197 },
          { name: '牧户26', value: 196 },
          { name: '牧户27', value: 195 },
          { name: '牧户28', value: 186 },
          { name: '牧户29', value: 184 },
          { name: '牧户1', value: 181 },
          { name: '牧户2', value: 177 },
          { name: '牧户3', value: 163 },
          { name: '牧户4', value: 142 },
          { name: '牧户5', value: 128 },
          { name: '牧户6', value: 117 },
          { name: '牧户7', value: 104 },
          { name: '牧户8', value: 92 },
          { name: '牧户9', value: 91 },
          { name: '牧户10', value: 90 },
          { name: '牧户11', value: 89 },
          { name: '牧户12', value: 87 },
          { name: '牧户13', value: 82 },
          { name: '牧户14', value: 78 },
          { name: '牧户15', value: 76 },
          { name: '牧户16', value: 76 },
          { name: '牧户17', value: 75 },
          { name: '牧户18', value: 72 },
          { name: '牧户19', value: 68 },
          
        ],

        list4: [
          { name: '牧户3', value: 224 },
          { name: '牧户4', value: 218 },
          { name: '牧户5', value: 201 },
          { name: '牧户6', value: 198 },
          { name: '牧户7', value: 195 },
          { name: '牧户8', value: 187 },
          { name: '牧户9', value: 185 },
          { name: '牧户1', value: 171 },
          { name: '牧户2', value: 167 },
          { name: '牧户10', value: 95 },
          { name: '牧户11', value: 88 },
          { name: '牧户12', value: 84 },
          { name: '牧户13', value: 82 },
          { name: '牧户14', value: 78 },
          { name: '牧户15', value: 74 },
          { name: '牧户16', value: 72 },
          { name: '牧户17', value: 71 },
          { name: '牧户18', value: 70 },
          { name: '牧户19', value: 68 },
          { name: '牧户20', value: 62 },
          { name: '牧户21', value: 58 },
          { name: '牧户22', value: 45 },
          { name: '牧户23', value: 41 },
          { name: '牧户24', value: 34 },
          { name: '牧户25', value: 32 },
          { name: '牧户26', value: 23 },
          { name: '牧户27', value: 16 },
          { name: '牧户28', value: 15 },
          { name: '牧户29', value: 8 },
        ],

        list5: [
          { name: '牧户21', value: 269 },
          { name: '牧户22', value: 268 },
          { name: '牧户23', value: 267 },
          { name: '牧户24', value: 263 },
          { name: '牧户25', value: 262 },
          { name: '牧户26', value: 258 },
          { name: '牧户27', value: 256 },
          { name: '牧户28', value: 255 },
          { name: '牧户29', value: 254 },
          { name: '牧户1', value: 253 },
          { name: '牧户2', value: 246 },
          { name: '牧户3', value: 235 },
          { name: '牧户4', value: 225 },
          { name: '牧户5', value: 213 },
          { name: '牧户6', value: 201 },
          { name: '牧户7', value: 184 },
          { name: '牧户8', value: 162 },
          { name: '牧户9', value: 151 },
          { name: '牧户10', value: 140 },
          { name: '牧户11', value: 139 },
          { name: '牧户12', value: 127 },
          { name: '牧户13', value: 102 },
          { name: '牧户14', value: 96 },
          { name: '牧户15', value: 86 },
          { name: '牧户16', value: 76 },
          { name: '牧户17', value: 75 },
          { name: '牧户18', value: 70 },
          { name: '牧户19', value: 69 },
          { name: '牧户20', value: 55 }
        ],

        list6: [
          { name: '牧户5', value: 168 },
          { name: '牧户6', value: 157 },
          { name: '牧户7', value: 145 },
          { name: '牧户8', value: 138 },
          { name: '牧户9', value: 136 },
          { name: '牧户1', value: 133 },
          { name: '牧户2', value: 121 },
          { name: '牧户3', value: 118 },
          { name: '牧户4', value: 107 },
          { name: '牧户10', value: 81 },
          { name: '牧户11', value: 78 },
          { name: '牧户12', value: 74 },
          { name: '牧户13', value: 56 },
          { name: '牧户14', value: 52 },
          { name: '牧户15', value: 51 },
          { name: '牧户16', value: 48 },
          { name: '牧户17', value: 47 },
          { name: '牧户18', value: 37 },
          { name: '牧户19', value: 33 },
          { name: '牧户20', value: 32 },
          { name: '牧户21', value: 26 },
          { name: '牧户22', value: 22 },
          { name: '牧户23', value: 21 },
          { name: '牧户24', value: 20 },
          { name: '牧户25', value: 19 },
          { name: '牧户26', value: 16 },
          { name: '牧户27', value: 15 },
          { name: '牧户28', value: 14 },
          { name: '牧户29', value: 11 }
        ]
      }
    },
    mounted() {
      this.hamletName = this.$route.params.val
      // console.log('villagePage : ', this.hamletName);

      setTimeout(() => {
        this.initChart()
        this.getMuHuList()
      }, 100)
    },
    methods: {
      // 初始化图表
      initChart() {
        this.data1 = {
          title: '养殖类',
          unit: '头',
          data: [
            {"name": "牛", "value": 3475, "unit": ''},
            {"name": "马", "value": 2348, "unit": ''},
            {"name": "绵羊", "value": 4336, "unit": ''},
            {"name": "山羊", "value": 3452, "unit": ''},
            {"name": "驴", "value": 3432, "unit": ''},
            {"name": "骆驼", "value": 5146, "unit": ''}
          ]
        }

        this.data2 = {
          title: '防疫统计',
          unit: '头',
          data: [
            {"name": "牛", "value": 2460, "unit": ''},
            {"name": "马", "value": 1738, "unit": ''},
            {"name": "绵羊", "value": 3736, "unit": ''},
            {"name": "山羊", "value": 1352, "unit": ''},
            {"name": "驴", "value": 1343, "unit": ''},
            {"name": "骆驼", "value": 2146, "unit": ''}
          ]
        }

        this.data3 = {
          title: {
            text: "死亡统计",
            right: "20px",
            top: '0%',
            textStyle: {
              color: "#fff"
            }
          },
          yName: '头',
          x: ['牛', '绵羊', '山羊', '马', '驴', '骆驼'],
          y: [18, 39, 12, 43, 24, 32, 13]
        }
      },

      // 获取牧户列表
      async getMuHuList() {
        let params = { village: "大板镇" }
        let res = await getMuHuList(params)
        
        this.muHuList = res.data.results.sort((a, b) => {
          return b.cattle_num - a.cattle_num
        })
      },

      // 跳转到牧户详情页
      goToMuHuDetail(id) {
        let muHuId = id

        this.$router.push({
          path: "/bigscreen/muHuPage" + muHuId
        })
      },

      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      },

      clickTab(val) {
        this.activeIndex = val
        if (val == 0) {
          this.list = this.list1
        } else if (val == 1) {
          this.list = this.list2
        } else if (val == 2) {
          this.list = this.list3
        } else if (val == 3) {
          this.list = this.list4
        } else if (val == 4) {
          this.list = this.list5
        } else if (val == 5) {
          this.list = this.list6
        }
      },

      // 跳转到 人口统计 图表详情
      goToPersonStateDetail() {
        let title = `xxx村人口统计`

        this.$router.push({
          path: "/bigscreen/personState" + title
        })
      },

      // 跳转到 机械化统计 图表详情
      goToMashineStateDetail() {
        let title = `xxx村机械化统计`

        this.$router.push({
          path: "/bigscreen/mashineState" + title
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .activeColor { color: #2366fc !important; }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    
  }
</style>
